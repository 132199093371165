<template>
    <Sprints 
        :filters="filters" 
        :showCreateButton="showCreateButton"
        inject
        :injectFormParams="injectFormParams"
        :excludeFields="excludeFields"
        :pageName="pageName" />
</template>

<script>
import Sprints from '@apps/vue2TaskComponent/components/Sprint/Sprints.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        Sprints
    },
    props: {
        id: {
            type: [String, Number],
            required: true
        },
        actions: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        ...mapGetters({
            requestData : "projects/info"
        }),
        pageName() {
            return `list_sprint_groups_and_project_${this.id}`
        }
    },
    data() {
        return {
            filters: {},
            excludeFields: ['status'],
            showCreateButton: false,
            injectFormParams: {}
        }
    },
    created() {
        if(this.actions?.add_sprint?.availability)
            this.showCreateButton = true

        this.filters = { 
            projects: this.id
        }
        this.injectFormParams = {
            projects: [this.requestData]
        }
        this.excludeFields.push('project')
    }
}
</script>

<style lang="scss">
.wrg_sprint{
    .sprint_wrapper{
        padding: 0;
    }
    height: 100%;
    position: relative;
}
</style>