<template>
    <a-card 
        size="small" 
        :title="isMobile ? false : $t('project.about_me')"
        :class="isMobile && 'about_card_mobile'">
        <div 
            v-if="isAuthor || privateGroup" 
            class="row_info flex-items-center">
            <a-tag 
                v-if="isAuthor"
                class="mr-2"
                color="green">
                {{ $t('project.is_author') }}
            </a-tag>
            <a-tag
                v-if="privateGroup"
                color="red">
                {{ $t('project.closed') }}
            </a-tag>
        </div>
        <div class="user-bio row_info">
            <h6 class="font-semibold">{{ $t('project.description')}}</h6>
            <p class="break-words">
                {{ descLength }}
            </p>
            <div v-if="showDescBtn">
                <span 
                    class="desc_more" 
                    @click="showDesc = !showDesc">
                    {{ showDesc ? $t('project.hide') : $t('project.more') }}
                </span>
            </div>
        </div>

        <!-- Срок сдачи проекта -->
        <div v-if="requestData.dead_line !== null" class="row_info">
            <h6 class="font-semibold">{{ $t("project.deadline_project") }}:</h6>
            <a-tag 
                color="purple" 
                class="mt-1">
                <span class="flex items-center">
                    <i class="fi fi-rr-clock-three mr-1"></i>
                    {{  $moment(requestData.dead_line).format("DD.MM.YYYY HH:mm") }}
                </span>
            </a-tag>
            <a-tag 
                v-if="requestData.control_dates"
                color="red" 
                class="mt-1">
                <span class="flex items-center">
                    {{ $t('project.control_on') }}
                </span>
            </a-tag>
        </div>

        <!-- Дата начала -->
        <div v-if="requestData.date_start_plan !== null" class="row_info">
            <h6 class="font-semibold">{{ $t("project.date_start_plan") }}:</h6>
            {{  $moment(requestData.date_start_plan).format("DD.MM.YYYY HH:mm") }}
        </div>

        <!-- Тип клуба -->
        <div 
            v-if="requestData.workgroup_type !== undefined && requestData.workgroup_type.name !== ''" 
            class="row_info">
            <h6 class="font-semibold">{{ $t("project.type") }}:</h6>
            <span>{{
                requestData.workgroup_type !== undefined
                    ? requestData.workgroup_type.name
                    : ""
            }}</span>
        </div>

        <!-- Кол-во участников -->
        <div 
            v-if="requestData.workgroup_members && requestData.workgroup_members.length" 
            class="row_info">
            <h6 class="font-semibold">{{ $t("project.participants_count") }}:</h6>
            <span>{{ participantsText }}</span>
        </div>
        
        <!-- Кол-во задач -->
        <div v-if="requestData.tasks" class="row_info">
            <div class="row_info" :class="isMobile && 'flex'">
                <h6 class="font-semibold">{{ $t("project.count_task") }}:</h6>
                <span :class="isMobile && 'ml-2'">{{ requestData.tasks }}</span>
            </div>
    
            <!-- Кол-во выполненных задач -->
            <div v-if="requestData.complete_tasks" :class="isMobile && 'flex'">
                <h6 class="font-semibold">{{ $t("project.tasks_complete") }}:</h6>
                <span :class="isMobile && 'ml-2'">{{ requestData.complete_tasks }}</span>
            </div>
    
            <div v-if="requestData.is_project">
                <a-progress
                    :stroke-color="{
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    }"
                    :percent="percent"/>
            </div>
        </div>

        <!-- Смета -->
        <div v-if="budgetStat" class="row_info">
            <h6 class="font-semibold">{{ $t('project.all_budget') }}:</h6>
            <span>{{ priceFormat(budgetStat.total_sum) }} <template v-if="budgetStat.currency">{{ budgetStat.currency.icon }}</template></span>
        </div>

        <div v-if="requestData?.location_point?.address" class="row_info">
            <h6 class="font-semibold">{{ $t('Address') }}:</h6>
            
            <!-- Marker -->
            <div class="flex">
                <svg class="mt-1 mr-1.5 shrink-0" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 0C3.67445 0.00145554 2.4036 0.527637 1.46625 1.46311C0.528902 2.39859 0.00159113 3.66697 0 4.98999C0 6.27499 0.996894 8.28599 2.96313 10.967C3.1972 11.287 3.50368 11.5474 3.85763 11.7269C4.21158 11.9064 4.60298 12 5 12C5.39702 12 5.78842 11.9064 6.14237 11.7269C6.49631 11.5474 6.80279 11.287 7.03687 10.967C9.00311 8.28599 10 6.27499 10 4.98999C9.99841 3.66697 9.4711 2.39859 8.53375 1.46311C7.59639 0.527637 6.32555 0.00145554 5 0ZM5 6.97899C4.60368 6.97899 4.21627 6.86169 3.88674 6.64193C3.55722 6.42217 3.30039 6.10981 3.14872 5.74436C2.99706 5.37891 2.95738 4.97677 3.03469 4.58881C3.11201 4.20085 3.30286 3.84449 3.58309 3.56478C3.86333 3.28508 4.22038 3.09459 4.60908 3.01742C4.99778 2.94025 5.40068 2.97986 5.76682 3.13124C6.13297 3.28261 6.44592 3.53896 6.6661 3.86785C6.88629 4.19675 7.00381 4.58343 7.00381 4.97899C7.00381 5.50942 6.79269 6.01813 6.41691 6.3932C6.04112 6.76828 5.53144 6.97899 5 6.97899Z" fill="#1D65C0"/>
                </svg>
                <span class="underline">{{ requestData.location_point.address }}</span>
            </div>
        </div>

        <div v-if="requestData?.funds" class="row_info">
            <h6 class="font-semibold">{{ $t('Budget') }}:</h6>
            <span>{{ priceFormat(requestData.funds) }} 
                млн. тг.
            </span>
        </div>



        <!-- Оценка -->
        <div v-if="difficultyStat" class="row_info">
            <h6 class="font-semibold">{{ $t('project.all_difficulty') }}:</h6>
            <span>{{ priceFormat(difficultyStat.total_avg) }}</span>
        </div>

        <!-- Программа -->
        <div v-if="requestData.program" class="row_info">
            <h6 class="font-semibold">{{ $t("project.program") }}:</h6>
            <span>{{ requestData.program.name }}</span>
        </div>

        <!-- Контрагент -->
        <div v-if="requestData.counterparty" class="row_info">
            <h6 class="font-semibold">{{ $t("project.counterparty") }}:</h6>
            <span>{{ requestData.counterparty.name }}</span>
        </div>

        <!-- Объект калькуляции -->
        <div v-if="requestData.costing_object" class="row_info">
            <h6 class="font-semibold">{{ $t("project.costing_object") }}:</h6>
            <span>{{ requestData.costing_object.name }}</span>
        </div>

        <div 
            v-if="requestData.organization" 
            class="row_info">
            <div class="mb-1 text-sm font-semibold">
                {{$t('project.organization')}}
            </div>
            <div 
                class="flex items-center cursor-pointer" >
                <!-- @click="openWorkgroup('viewGroup', task.organization)" -->
                <div>
                    <a-avatar 
                        :src="requestData.organization.logo" 
                        icon="team" 
                        :size="32" />
                </div>
                <span class="ml-2">{{ requestData.organization.name }}</span>
            </div>
        </div>


        <!-- Дата основания -->
        <div class="row_info">
            <h6 class="font-semibold">{{ $t("project.date_create") }}:</h6>
            <span>{{
                $moment(requestData.created_at).format("DD.MM.YYYY")
            }}</span>
        </div>
        <!-- Соц сети -->
        <div
            v-if="requestData.social_links.length > 0"
            class="social-links flex-column mt-4">
            <h6 class="font-semibold">{{ $t("project.social_links") }}:</h6>
            <div
                v-for="link in requestData.social_links"
                :key="link.id"
                class="flex">
                <a
                    class="mt-1"
                    target="_blank"
                    :href="link.social_link">{{ link.social_web_type.name }}</a>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapState } from "vuex"
import {declOfNum} from '../../utils'
import { priceFormatter } from '@/utils'
export default {
    name: "GroupsAndProjectAbout",
    props: {
        requestData: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        participantsText() {
            return this.requestData.workgroup_members.length + ' ' +
             declOfNum(this.requestData.workgroup_members.length, [this.$t('project.participant'), this.$t('project.participant2'), this.$t('project.participant3')])
        },
        descLength() {
            if(!this.showDesc && this.requestData?.description?.length > 175)
                return this.requestData.description.substr(0, 175) + '...'
            else
                return this.requestData.description
        },
        isAuthor() {
            if(this.user && this.user.id === this.requestData?.founder?.member?.id)
                return true
            else
                return null
        },
        privateGroup() {
            if(this.requestData?.public_or_private)
                return true
            else
                return false
        },
        percent(){
            return parseInt(((this.requestData.complete_tasks / this.requestData.tasks) * 100).toFixed(2))
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            showDesc: false,
            showDescBtn: false,
            budgetStat: null,
            difficultyStat: null
        }
    },
    created() {
        if(this.requestData?.description?.length > 175)
            this.showDescBtn = true

        this.getBudget()
        this.getDifficulty()
    },
    methods: {
        priceFormat(price) {
            return priceFormatter(String(price))
        },
        async getBudget() {
            if(!this.budgetStat) {
                try {
                    const { data } = await this.$http.get('/tasks/budget/aggregate/', {
                        params: {
                            obj: this.requestData.id
                        }
                    })
                    if(data) {
                        this.budgetStat = data
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        },
        async getDifficulty() {
            if(!this.difficultyStat) {
                try {
                    this.loading = true
                    const { data } = await this.$http.get('/tasks/difficulty/aggregate/', {
                        params: {
                            obj: this.requestData.id
                        }
                    })
                    if(data) {
                        this.difficultyStat = data
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            }
        }
    }
}
</script>

<style lang="scss">
.desc_more{
    color: var(--primaryColor);
    border-bottom: 1px dashed;
    cursor: pointer;
    font-size: 13px;
}

.about_card_mobile {
    .ant-card-body {
        padding: 0;
    }
}
.row_info {
    padding: 0.5rem 0;
}
.row_info + .row_info {
    border-top: 1px solid var(--borderColor);
}
</style>